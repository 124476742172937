.banner {
    background: rgba(194, 233, 246, 0.461);
    height: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.banner__text {
    z-index: 20;
    margin-bottom: 20px;
}

.banner__text>h2 {
    margin-bottom: 10px;
    color: var(--dark-green);
}

.banner__image>img {
    border-radius: 1rem;
}

@media screen and (max-width: 1100px) {
    .banner {
        /* height: 700px; */
        flex-direction: column;
        flex-wrap: wrap-reverse;
        justify-content: center;
        align-items: center;
        padding: 30px;
    }

    .banner__text>p {
        font-size: small;
    }

    .banner__image>img {
        width: 400px;

    }
}

@media screen and (max-width: 450px) {
    .banner {
        padding: 10px;
    }

    .banner__image>img {
        width: 300px;
    }
}