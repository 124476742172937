.sect {
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    justify-content: space-around;
    font-size: 20px;
    gap: 20px;
    font-weight: 300;
    line-height: 1.5;
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity));
    word-spacing: 5%;

}

.overviewtext {
    width: 100%;
    font-size: 1rem;
    padding: 20px;
    line-height: 25px;
    text-align: justify;
}

.container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

/* @media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
} */

@media (min-width: 1036px) {
    .container {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    .sect {
        display: flex;
        /* font-family: Georgia, 'Times New Roman', Times, serif; */
        justify-content: space-around;
        padding: 28px;
        font-size: 20px;
        margin: 20px;
        gap: 20px;
        font-weight: 300;
        line-height: 1.5;
        --tw-text-opacity: 1;
        color: rgb(0 0 0/var(--tw-text-opacity));
    }
}