.subcontent {
    max-width: 750px;
    background-color: #fff;
    border-radius: 8px;
    padding: 45px 20px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    text-align: center;
    margin-bottom: 60px;
    text-align: center;
    color: rgb(219, 170, 121);
    padding: 0px 15px;
    font-weight: bold;
    font-family: Lora, serif;
    font-weight: 300;
    text-transform: uppercase;
}

.text {
    font-size: 1.2rem;
}