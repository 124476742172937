.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6rem 0rem;
}

.pageSection {
    max-width: 85%;
    margin: auto;
}


.daybyday {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.15;
    font-weight: 400;
    text-align: center;
    font-size: calc(1.375rem + 1.5vw);
    color: #a39161;
    text-transform: uppercase;
}

.day1 {
    margin-bottom: 0;
}

.goldLinedTop {
    margin-top: 1rem;
    border-top: 3px solid #a39161 !important;
}

/*
.block {
    display: block;
} */

.pt_6 {
    padding-top: 1rem !important;
}

.textBlack {
    color: #000;
}

.mt5 {
    margin: 0.5rem 0rem;
}

.mt12 {
    margin: 1.5rem 0rem;
}

.leadingNormal {
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 600;
    text-align: justify;
}