@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aleo:wght@300&family=Montserrat:wght@300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital@0;1&display=swap');

* {
  padding: 0%;
  margin: 0%;
  /* font-family: Aleo, Georgia, serif; */
  font-family: "Rubik", sans-serif;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  display: flex;
  justify-content: center;
}

body {
  width: 100%;
  overflow-x: hidden;
  max-width: 1600px;
}

:root {
  --black: black;
  --white: white;
  --golden: #e4bd5a;
}

a {
  text-decoration: none;
  color: white;
}

.btn-a {
  padding: 0.6rem 0.5rem;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}

.btn-a-outline {
  background-color: var(--golden);
  color: white;
  border-radius: 8px 2px 8px 2px;
}

.btn-a-outline:hover {
  transition: all;
  transform: scale(1.1);
}

.btn-a-solid {
  background-color: var(--golden);
  border: 1px solid var(--golden);
  color: white;
  border-radius: 8px 2px 8px 2px;
}

h2 {
  font-size: 2.2rem;
}

.btn-a-solid:hover {
  background-color: transparent;
  color: var(--golden);
}

h1 {
  font-size: 4rem;
  font-weight: 900;
}



.input-box {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--golden);
  outline: none;
}

.scrollable-section {
  width: 100%;
  display: flex;
  gap: 2rem;
  overflow-x: scroll;
}

@media screen and (max-width: 600px) {
  html {
    font-size: 90%;
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none !important
}