input::placeholder {
  color: #a8a9a8;
}

select::placeholder {
  color: #a8a9a8;
}

.enquire_container {
  width: 70%;
  margin: auto;
}

.enquire_container label {
  font-size: 17px;
  font-weight: 500;
}

.enquire_container p {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin: 3rem 0rem;
}

.enquire_form {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.enquire_form_left {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.enquire_form_right {
  width: 47%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.enquire_form_left>div {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  text-align: left;
}

.enquire_form_right>div {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  text-align: left;
  align-items: center;
}

.enquire_form input,
.enquire_form select {
  background-color: #f1f0f3;
  border: none;
  outline: none;
  font-size: 17px;
  padding: 9px 31px;
  border-radius: 5px;
  color: #3c3d3d;
  height: 35px !important;
}



.enquire_form textarea {
  background-color: #f1f0f3;
  border: none;
  outline: none;
  font-size: 17px;
  padding: 9px 28px;
  border-radius: 5px;
  color: #3c3d3d;
}

.enquire_form select {
  max-width: 266px;
}

.enquire_agency_container {
  width: 266px;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.enquire_agency_container>input {
  width: 60%;
}

.enquire_phone_container>div {
  width: 266px;
  display: flex;
  gap: 10px;
}

.enquire_phone_container>div span {
  width: 20%;
  background-color: #f1f0f3;
  padding: 12px 28px;
}

.enquire_phone_container>div input {
  width: 100%;
  background-color: #f1f0f3;
}

.months_year_container {
  width: 266px;
  display: flex;
}

.enquire_right_btn {
  width: 120px;
  border: none;
  background-color: transparent;
  border-radius: 16px 2px 16px 2px;
  border: 1px solid #a39060;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  padding: 10px;
}

.enquire_right_btn:hover {
  background-color: #a39060;
  color: white;
}

.enquire_agency_checkbox>label {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.enquire_agency_container_none {
  display: none;
}

@media (max-width: 770px) {
  .enquire_container p {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    margin: 3rem 0rem;
  }

  .enquire_container {
    width: 90%;
    margin: auto;
  }

  .enquire_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }

  .enquire_form_left>div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    text-align: left;
  }

  .enquire_form_right>div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    text-align: left;
  }

  .enquire_form_left {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .enquire_form_right {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .enquire_phone_container>div {
    width: 100%;
    display: flex;
    gap: 10px;
  }

  .enquire_agency_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
  }

  .enquire_agency_container>input {
    width: 70%;
  }
}