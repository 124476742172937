.about-us-section {
  margin: 2rem 0;
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  background-position: center;
  position: relative;
  color: white;
  border: 2px solid var(--golden);
  font-family: "Aleo", serif;
  background-color: rgba(9, 9, 9, 0.522);
}

.about-us-sectionleft {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.about-us-sectionright {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-us-sectionleft>img {
  width: 30vw;
  height: 60vh;
  object-position: center;
  border-radius: 5px;
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  transform: rotate(-3deg);
  border-width: 5px 15px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  border: 2px solid var(--golden);
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.about-us-section::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.about-us-section h2 {
  position: relative;
  color: white;
}

.about-us-section-underline {
  max-width: 700px;
  text-align: center;
  font-size: 1.2rem;
  font-family: Aleo, Georgia, serif;
}

.about-us-sectionleft>img:hover {
  transform: rotate(3deg);
}

.about-us-sectionleft.rotate {
  animation: rotate 0.3s ease-in-out alternate infinite;
}

.about-us-section-para-portion {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  font-family: Aleo, Georgia, serif;
  text-align: justify;
  font-size: 1.2rem;
  /* word-break: break-all; */
  /* align-items: center; */
}

.about-us-section-para-portion-left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 5px;
  text-align: justify;

}

.about-us-section-para-portion-right {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 750px) {
  .about-us-section {
    margin: 2rem 0;
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    background-position: center;
    position: relative;
    color: white;
    height: 100%;
    border: 2px solid var(--golden);
    font-family: "Aleo", serif;
    background-color: rgba(9, 9, 9, 0.522);
  }

  .about-us-sectionleft {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    padding: 10px;
    justify-content: center;
  }

  .about-us-sectionright {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-us-sectionleft>img {
    width: 50vw;
    height: 50vh;
    object-position: center;
    border-radius: 5px;
    border: 2px solid #fff;
  }

  .about-us-section::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
  }

  .about-us-section h2 {
    position: relative;
    color: white;
  }


  .about-us-section-para-portion {
    width: 100%;
    text-align: center;
    text-align: justify;
  }

  .about-us-section-para-portion-left {
    width: 100%;
    text-align: center;
    text-align: justify;
  }

}