/* Form.css */
.form-container {
    max-width: 750px;
    background-color: #fff;
    border-radius: 8px;
    padding: 45px 20px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-header {
    text-align: center;
    margin-bottom: 60px;
    text-align: center;
    color: rgb(219, 170, 121);
    padding: 0px 15px;
}

.form-title {
    font-weight: bold;
    font-family: Lora, serif;
    font-weight: 300;
    text-transform: uppercase;
}

.form-control {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-control>label {
    flex: 4;
    font-weight: bold;
    text-align: start;
}

.form-input {
    flex: 5;
    width: 100%;
    padding: 8px;
    margin-left: 20px;
    display: flex;
    justify-content: flex-end;
    border: 1px solid rgb(219, 170, 121);
    border-radius: 4px;

}

.form-input:focus {
    border-color: rgb(219, 170, 121);
}

.form-actions {
    text-align: center;
    margin-top: 20px;
}

.buttonsubmitform {
    color: #fff;
    width: 115px;
    margin: 0px 8px;
    padding: 8px 16px;
    font-size: 16px;
    background-color: #222;
    outline: none;
    border-radius: 5px;
    border: none;
    border-radius: 8px 2px 8px 2px;
    cursor: pointer;
}

.form-button.primary {
    background-color: #007bff;
    color: white;
    border: none;
}