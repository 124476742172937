.callus_container {
    width: 80%;
    margin: auto;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
}

.callus_container>div {
    width: 50%;
    text-align: left;
    padding: 0px 10px;
}

.callus_container .left p {
    font-size: 18px;
    font-weight: 400;
    height: 120px;
}

.callus_container .left h2 {
    font-size: 16px;
    font-weight: 600;
}


.callus_container .right p {
    font-size: 18px;
    font-weight: 400;
    height: 120px;
}

.callus_container .right h2 {
    font-size: 16px;
    font-weight: 600;
}

.callus_container .right h3 {
    max-width: 430px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
}

@media (max-width: 770px) {
    .callus_container {
        width: 90%;
        margin: auto;
        margin-top: 100px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .callus_container>div {
        width: 100%;
        text-align: left;
        padding: 0px 10px;
    }
}