.MainUpper {
  padding: 0%;
  margin: 0%;
  height: 95vh;
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
}

.backgorundImage {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  object-position: center;
  outline: none;
}

.MainContent {
  color: #fff;
  width: 100%;
  margin: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.Title {
  font-size: 3.375rem;
  font-weight: 400;
  margin: 0;
  line-height: 1.1;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  text-shadow: 0 3px 5px #000;
}

.Italic {
  font-weight: 400;
  font-size: 2.375rem;
  line-height: 1.7;
  text-align: center;
  color: #fff;
  text-shadow: 0 3px 5px #000;

}

.GoButton {
  margin: 2%;
}

.ButtonFirst {
  width: 300px;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-family: Roboto;
  font-weight: 700;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  padding: 0.563rem 1.125rem;
  font-size: 1.125rem;
  border-radius: 0;
  line-height: 1.4;
  color: #fff;
  background-color: rgba(51, 51, 51, .5);
  border: 3px solid #fff;
  cursor: pointer;

}

.Mainmid {
  padding: 0%;
  /* margin: 0%; */
  /* border: 1px solid black; */
  height: auto;
  /* padding-bottom: 1%; */
  width: 100%;
  /* background-color:black; */
}

.AddContent {
  max-width: 1380px;
  width: 90%;
  margin: auto;
  text-align: center;
}

.TitleSet {
  margin: 20px 0px;
  line-height: 1.1;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;


}

.ParaSet {
  letter-spacing: .6px;
  text-align: center;
  color: black;
  font-size: 1.2rem;
  text-align: justify;
}


.MainDiv {
  height: 80px;
  max-width: 1380px;
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;
  background-color: #444;
  text-align: center;
}


.AddMore {
  height: auto;
  padding-bottom: 1%;
  width: 70%;
  margin: 4% 5% 5% 15%;

}

.ItalicData {
  color: white;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.7;
  text-align: center;
  color: #fff;
  padding-top: 2.5%;
}


.HomeHead {
  width: 100%;
  text-align: center;
  padding: 20px;
  margin: 20px 0px;

}

.HomeHeadingData {
  line-height: 1.1;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: auto;
}

/* .MainData
  {
    line-height: 2.5;
    text-align: center;
  } */


.GridData {
  display: grid;
  grid-template-columns: repeat(3, 470px);
  /* gap: 1%; */
  /* padding: 1%; */
  /* gap:10px; */
  /* border: 1px solid black;
    height:auto;
    padding-bottom: 1%;
    width: 100%;
    display: grid;
    gap:2%;
    grid-template-columns: repeat(3,450px); */
}

.GridData>div {
  border: 1px solid black;
  width: 100%;
  margin: 10%;

}

.GridFill {
  height: 400px;
  width: 100px;
  /* border: 1px soild black; */
}

.HomeImageSet {
  width: 100%;
  /* padding: 2%; */
}

.EntityOne {
  /* height: 70px; */
}

.EntityOne>div {
  border: 2px solid black;
  /* height: 30px; */
  margin: 20px 100px;
  text-align: Center;
  font-size: 16px;
}

.EntityTwo {
  text-align: center;
}

@media only screen and (max-width: 1000px) and (min-width: 700px) {
  .MainUpper {
    padding: 0%;
    margin: 0%;
    height: 95vh;
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .GridData {
    display: grid;
    grid-template-columns: repeat(2, 410px);
  }

  .EntityOne>div {
    border: 2px solid black;
    height: 30px;
    margin: 20px 100px;
    text-align: Center;
    font-size: 14px;
  }

  .Title {
    font-size: 2.375rem;
  }

}

@media only screen and (max-width: 700px) and (min-width: 300px) {
  .MainUpper {
    padding: 0%;
    margin: 0%;
    /* border: 1px solid black; */
    height: 100vh;
    width: 100%;
    background-color: black;
    /* margin-top: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-image: url("https://res.cloudinary.com/dxhoawdbh/image/upload/v1711518207/82ba7453-0ece-45cd-b139-3fabe77a1922_fy8osv.jpg");
    background-position: 20% 50%;
    margin-top: 50px;
  }

  .GridData {
    display: grid;
    grid-template-columns: repeat(1, 85%);
  }

  .EntityOne>div {
    border: 2px solid black;
    /* height: 30px; */
    margin: 20px 100px;
    text-align: Center;
    font-size: 14px;
  }

  .Title {
    font-size: 2.375rem;
  }

  .Italic {
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 1.5;
    text-align: center;
    color: #fff;
    text-shadow: 0 3px 5px #000;
    margin: 20px;
  }

}