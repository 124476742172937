.subcontent {
  max-width: 750px;
  background-color: #fff;
  border-radius: 8px;
  padding: 45px 20px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Occasionheader {
  text-align: center;
  margin-bottom: 60px;
  text-align: center;
  color: rgb(219, 170, 121);
  padding: 0px 15px;
  font-weight: bold;
  font-family: Lora, serif;
  font-weight: 300;
  text-transform: uppercase;
}

.MuiFormGroup_root {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  outline: none;
  font-size: 0.9rem;
}

.experiencesOther {
  width: 50%;
  margin: auto;
}

.radiogroup {
  padding: 0px 35px;
  display: inline-flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
}

.customRadioGroup input[type="radio"] {
  flex-direction: row;
  width: 20px;
  height: 20px;
  background-color: black;
  border-radius: 50%;
  border: 2px solid #000;
  margin-right: 8px;
}

/* Style for the checked radio button (when selected) */
.customRadioGroup input[type="radio"]:checked {
  background-color: black;
  /* Set the background color of the checked radio button to black */
}

.containerbtn {
  max-width: 600px;
  margin: 0 auto;
  color: #494949;
  padding: 15px 0px;
  cursor: pointer;
}

.containerbtn:hover {
  box-shadow: 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}