.testimonial-card {
    min-width: 250px;
    height: 300px;
    perspective: 1000px;
    transition: transform 0.5s;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
  }
  .testimonial-card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .testimonial-card-overlay{
    position: absolute;
    top: -100%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.489);
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: white;
    text-align: center;
    font-size: 12px;
    transition: 0.8s;
    }
    .testimonial-card:hover>.testimonial-card-overlay{
      top: 0%;
    }