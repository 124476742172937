/*
===============
Variables
===============
*/

:root {
    /* dark shades of primary color*/
    --clr-primary-1: hsl(205, 86%, 17%);
    --clr-primary-2: hsl(205, 77%, 27%);
    --clr-primary-3: hsl(205, 72%, 37%);
    --clr-primary-4: hsl(205, 63%, 48%);
    /* primary/main color */
    --clr-primary-5: hsl(205, 78%, 60%);
    /* lighter shades of primary color */
    --clr-primary-6: hsl(205, 89%, 70%);
    --clr-primary-7: hsl(205, 90%, 76%);
    --clr-primary-8: hsl(205, 86%, 81%);
    --clr-primary-9: hsl(205, 90%, 88%);
    --clr-primary-10: hsl(205, 100%, 96%);
    /* darkest grey - used for headings */
    --clr-grey-1: hsl(209, 61%, 16%);
    --clr-grey-2: hsl(211, 39%, 23%);
    --clr-grey-3: hsl(209, 34%, 30%);
    --clr-grey-4: hsl(209, 28%, 39%);
    /* grey used for paragraphs */
    --clr-grey-5: hsl(210, 22%, 49%);
    --clr-grey-6: hsl(209, 23%, 60%);
    --clr-grey-7: hsl(211, 27%, 70%);
    --clr-grey-8: hsl(210, 31%, 80%);
    --clr-grey-9: hsl(212, 33%, 89%);
    --clr-grey-10: hsl(210, 36%, 96%);
    --clr-white: #fff;
    --clr-purple: #4b145b;
    --clr-red-special: #b4345c;
    --clr-grey-special: #eae6eb;
    --clr-red-dark: hsl(360, 67%, 44%);
    --clr-red-light: hsl(360, 71%, 66%);
    --clr-green-dark: hsl(125, 67%, 44%);
    --clr-green-light: hsl(125, 71%, 66%);
    --clr-black: #222;
    --transition: all 0.3s linear;
    --spacing: 0.1rem;
    --radius: 0.25rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    --max-width: 1170px;
    --fixed-width: 920px;
}

/*
===============
Global Styles
===============
*/





/* section */
.section {
    width: 100vw;
    margin: 0 auto;
    max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
    .section {
        width: 95vw;
    }
}

/*
===============
Questions
===============
*/

main {
    /* min-height: 100vh; */
    /* using flex because of better browser support */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container {
    width: 90vw;
    background: var(--clr-white);
    border-radius: var(--radius);
    padding: 0.5rem;
    max-width: var(--fixed-width);
    gap: 1rem 2rem;
}

.acc-btn-container {
    width: 95%;
    background: var(--clr-white);
    border-radius: var(--radius);
    max-width: var(--fixed-width);
    display: flex;
    justify-content: flex-end;
}

.container h3 {
    line-height: 1.2;
    font-weight: 500;
}

@media screen and (min-width: 992px) {}

.question {
    padding: 1rem;
    border: 2px solid var(--clr-grey-special);
    margin-bottom: 1rem;
    border-radius: var(--radius);
    box-shadow: var(--light-shadow);
    text-align: left;
    font-size: 1.2rem;
}

.question h4 {
    text-transform: none;
    line-height: 1.5;
}

.question p {
    margin-bottom: 0;
    margin-top: 0.5rem;
}

.question header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question header h4 {
    margin-bottom: 0;
}

.btn {
    background: transparent;
    border-color: transparent;
    width: 2rem;
    height: 2rem;
    background: var(--clr-grey-special);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--clr-red-special);
    cursor: pointer;
    align-self: center;
    min-width: 2rem;
}

.question-title {
    cursor: pointer;
}

.activities {
    padding: 1rem;
    text-align: left;
    font-size: 1rem;
}

.activities li {
    margin-bottom: 5px;
}

.expand-btn {
    background: transparent;
    border-color: transparent;
    width: 5rem;
    height: 2rem;
    background: var(--clr-grey-special);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5%;
    color: var(--clr-red-special);
    cursor: pointer;
    margin-left: 1rem;
    align-self: center;
    min-width: 2rem;
}