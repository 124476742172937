.navContainer {
  display: flex;
  /*   justify-content: coloumn; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  position: sticky;
  top: 0;
  /* background-color: black; */
  height: 80vh;
  padding: 20px;
  /*   overflow-x: scroll; */
  background: lightgray;
}

.navlink {
  /* border-radius: 5px; */
  padding: 10px 20px;
  background-color: white;
  margin: 5px;
  min-width: 300px;
  color: gray;
  font-weight: 700;
}



@media only screen and (max-width: 954px) {
  .navContainer {
    display: none;
  }
}