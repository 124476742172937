/* booking-term.css */

/* General styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.text-center {
    text-align: center;
}

.container {
    width: 90%;
    margin: 0 auto;
}

/* Breadcrumb section */
.breadcrumb-outer {
    background-attachment: fixed;
    background-size: cover;
    padding: 100px 0;
}

.breadcrumb-content h2 {
    color: #fff;
    font-size: 36px;
    margin-bottom: 20px;
}

.breadcrumb {
    list-style: none;
    padding: 0;
    margin: 0;
}

.breadcrumb-item {
    display: inline;
    margin-right: 5px;
}

.breadcrumb-item a {
    color: #fff;
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #ffd700;
}

/* Section overlay */
.section-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Main content section */
.why-us {
    padding: 40px 0;
}

.why-about-inner h4 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 15px;
}

.why-about-inner p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.why-about-inner ul {
    padding-left: 20px;
}

/* Booking terms section */
.booking_terms {
    background-color: #f9f9f9;
    padding: 40px 0;
}

.booking_terms h4 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 15px;
}

.booking_terms ul {
    padding-left: 20px;
}

.booking_terms p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
}