.award-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  background-color: black;
  padding: 2rem 2rem;
}
.awards {
  text-align: center;
  color: white;
}
.awards h3 {
  font-size: 1rem;
}
.awards p {
  color: var(--golden);
}

@media screen and (max-width: 600px) {
  .awards h3 {
    font-size: 1rem;
  }
  .awards p {
    font-size: 8px;
  }
}
