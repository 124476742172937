.MainUpperr {
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
}

.backgorundImage {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  object-position: center;
  outline: none;
}


.MainContent {
  color: #fff;
  width: 100%;
  margin: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Title {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
  line-height: 1.1;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 3px 5px #000;

  padding: 5px 5px;
}

.Italic {
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.2;
  text-align: center;
  color: #fff;
  text-shadow: 0 3px 5px #000;

  padding: 0px 5px;
}

.GoButton {
  margin: 1%;
  cursor: pointer;
}

.ButtonFirst {
  width: 300px;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: 700;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  padding: 0.563rem 1.125rem;
  font-size: 1.2rem;
  border-radius: 0;
  line-height: 1.4;
  color: #fff;
  background-color: rgba(51, 51, 51, .5);
  border: 3px solid #fff;
  cursor: pointer;
}

.ButtonFirst:hover {
  transition: all;
  transform: scale(1.1);
}

.Capp {
  text-align: center;
}


.Flex {
  display: flex;
}

#section1 {
  background-color: white;
}

.SettingHeading {
  text-align: center;
}

.SetText {
  font-size: 2.2rem;
  color: #333;
  font-weight: 700;
  letter-spacing: .1rem;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 450px) {

  .SetText {
    font-size: 1.5rem;
    color: #333;
    font-weight: 700;
    letter-spacing: .1rem;
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
  }

}


.SetTextWith {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 5vh;
}

.SetFlexProperty {
  height: auto;
  padding-bottom: 1px;
  width: 100%;
  display: flex;
  flex-direction: row;

}

.SetFlexProperty div {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: justify;

}

.SetDataLeft {
  font-weight: 700;
  padding-left: 1.6rem;
  color: #666;
  font-size: 1.2rem;
}

.SetDataLeft>ul {
  margin-left: 5px;
}

.SetDataLeft>ul>li {
  margin: 20px;
  text-align: left;
  font-size: 1.2rem;
  list-style: disc;
}

.SetDataright {
  padding-left: 1rem;
  font-size: 1.2rem;
  line-height: 2.2rem;
  color: #333;
}


@media only screen and (max-width: 954px) {
  .SetFlexProperty {
    height: auto;
    padding-bottom: 1px;
    width: 100%;
    display: flex;
    flex-direction: column;

  }

  .SetFlexProperty div {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: justify;

  }
}

.SettingHeadingRatio {
  padding: 2%;
  height: auto;
}

.SetTextRatio {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: .1rem;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;

}




.inner_container {
  width: 100%;
  margin: auto;
  line-height: 1.5;
}

.exclusivesavings {
  font-size: 1.2rem;
  text-align: justify;
  line-height: 2;
  padding: 1rem;
}

.SetBackGround {
  position: absolute;
  width: 100%;
  height: 90%;
  -o-object-fit: cover;
  object-fit: cover;
  outline: none;
  border: none;
  box-shadow: none;
  z-index: 0;

}


.EnterData {
  height: auto;
  padding: 2%;
  font-size: 1.2rem;
}

.PutData {
  text-align: left;
  height: auto;
  width: 98%;
}

.DataHeading {
  font-size: 2.2rem;
  color: #333;
  font-weight: 700;
  letter-spacing: .1rem;
  gap: 20px;
  text-align: center;
  text-transform: uppercase;

}

.HeadingContent {
  line-height: 1.6;
  font-size: 1.2rem;
  color: #666;
  font-weight: 600;
  margin-left: 30px;
  margin: 20px;
}


.table_price {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 434px) and (min-width: 300px) {

  .Title {
    font-size: 1.5rem;
  }

  .Italic {
    font-weight: 400;
    font-size: 1.375rem;
  }
}

@media only screen and (max-width: 360px) {

  .SetFlexProperty {
    height: auto;
    padding-bottom: 1px;

    width: 100%;
    display: flex;
    flex-direction: column;

  }

  .SetFlexProperty div {
    height: auto;
    display: flex;
    flex-direction: row;
    text-align: justify;
  }
}


#section2 {
  background-color: white;
  margin-top: 2%;
  height: auto;
  width: 100%;
}


.SectionTwo {
  height: auto;
  width: 100%;
}

.SectionTwo>img {
  height: 200px;
  margin: 1%;
  width: 90%;
}


.SecondFlex {
  width: 100%;
}

.SetSecondFlex {
  margin: 0% 5%;
  width: 90%;
  display: flex;
  gap: 2px;
}

.SetSecondFlex>img {
  height: 160px;
  width: 20%;
  display: flex;
}

.Pet {
  width: 150px;
  height: 100px;
}

#section3 {
  background-color: white;

}

.SectionThreeMain {
  height: auto;
  padding-bottom: 1%;
  width: 100%;
  display: flex;
  flex-direction: row;

}

.SectionThreeMain>div {
  height: auto;
  display: flex;
  flex-direction: row;
  margin: 1%;
  display: flex;
  flex-direction: column;

}

.FirstDiv {
  width: 100%;
}


.SecondDiv {
  width: 100%;
  text-align: left;
  color: #333;
  border-left: 5px solid #aaaaaa5e;
  padding: 0px 20px;
  text-align: justify;
  font-size: 1.2rem;

}

.tripfeedbackquote {
  font-size: 1.2rem;
  font-weight: 100;
  margin: 10px 0px;
  text-align: justify;
  width: 98%
}

.ThirdPic {
  width: 100%;
  height: 300px;

}

.ParaThird {
  font-size: 1.8rem;
  font-style: italic;
  color: #aaa;
  font-weight: 100;
}

@media only screen and (max-width: 700px) and (min-width: 360px) {


  .SectionThreeMain {
    height: auto;
    padding-bottom: 1%;
    width: 100%;
    display: flex;
    flex-direction: column;

  }

  .SectionThreeMain>div {
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 1%;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
  }

  .FirstDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .SecondDiv {
    width: 100%;
    font-size: 1.2rem;
  }

}

#section4 {
  background-color: white;
}

.FourFlex {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 3%;
}


.FourFlex div {
  text-align: left;
  height: auto;
  width: 45%;
  margin: 2.5%;

}

.FourAling {
  padding-left: 23px;
  font-size: 2rem;
  font-weight: 600;
  color: #333;
}

.FourSubHeading {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
}

.FourPara {
  font-size: 1.5rem;
  line-height: 2.2rem;
  color: #666666;
}

.SetLi {
  color: #333;
  font-size: 19px;
  line-height: 1.4;
}



.LastFourContent {
  width: 100%;

}

.LastFourContent>div {
  height: auto;
  padding-bottom: 1%;
  width: 90%;
  margin: 5%;
  text-align: center;
}

.ContentSubHeading {
  color: #333;
  font-size: 2.2rem;
  letter-spacing: .1rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
}

.ContentSubHeadingPart {
  color: #333;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: justify;
}

@media only screen and (max-width: 700px) and (min-width: 360px) {

  .FourFlex {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 3%;
  }

  .FourFlex div {
    height: auto;
    width: 90%;
    margin: 5%;
    text-align: left;
  }

}


.tab_content {
  display: block;
  margin: 0rem 2rem;
}

.table_data table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem;
}

.table_data th,
.table_data td {
  padding: 8px;
  text-align: left;
  border: 2px solid #222;
  text-align: center;
  font-size: 1rem;
}

.table_data th {
  background: #2a2a2a;
  color: #fff;
  font-size: 1rem;
}

@media (max-width: 650px) {

  .table_bordered {
    display: flex;
  }

  .table_bordered>thead>tr,
  .table_bordered>tbody>tr {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .table_data th,
  .table_data td {
    height: 60px;
    padding: 1.3rem;
    text-align: left;
    border: 2px solid #222;
    text-align: center;
    font-size: 1.3rem;
  }
}

@media (max-width: 450px) {

  .table_bordered {
    display: flex;
  }

  .table_bordered>thead>tr,
  .table_bordered>tbody>tr {
    width: 150px;
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow: hidden;
  }

  .table_data th,
  .table_data td {
    height: 60px;
    padding: 1rem;
    text-align: left;
    border: 2px solid #222;
    text-align: center;
    font-size: 1.2rem;
  }
}

.btn_blue {
  color: #000;
}

.btn_booknow {
  background-color: #e4bd5a;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 8px 2px 8px 2px;
}

.btn_booknow:hover {
  transition: all;
  transform: scale(1.1);
}

/* ///////map-style///////// */
.map {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 350px;
}

.image_container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.image_container:hover .map {
  display: block;
}

.travel_sts {
  display: flex;
  justify-content: center;

}

.travel_sts>img {
  margin: 0px 5px;
}

.checkIcon {
  font-size: 16px;
  background-color: rgb(86, 241, 39);
  width: 20px;
  color: white;
  border-radius: 50%;
  margin-right: 5px;
}

.crossIcon {
  color: red;
  font-size: 16px;
  background-color: rgb(86, 241, 39);
  width: 20px;
  border-radius: 50%;
  margin-right: 5px;
}