.navbar {
  width: 100%;
  position: fixed;
  top: 65px;
  padding: 0.7rem 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(20px);
  font-size: 14px;
  z-index: 1000;
  background-color: rgb(241, 237, 237);
}

.navbar-logo {
  width: 200px;
  height: 50px;
  font-size: 1.3rem;
}

.sidedivheader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.call-and-home-nav-item {
  width: fit-content;
  display: -webkit-inline-box;
  align-items: center;
  gap: 0.5rem;
  color: black !important;
}

.call-and-home-nav-item>a:hover {
  color: var(--golden) !important;
}

.call-and-home-nav-item>p {
  margin: auto;
}

.nav-items {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.7rem;
  font-size: 1.5rem !important;
}

.nav-items>li {
  list-style: none;
  margin-left: 0.5rem;
}

.drop-down-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.drop-down-menu {
  position: absolute;
  top: 15px;
  background-color: var(--black);
  padding: 1.5rem 1.5rem;
  display: none;
  flex-direction: column;
  gap: 1rem;
  min-width: 200px;
  border-radius: 8px;
  transition: 0.6s;
}

.drop-down-item:hover>.drop-down-menu {
  display: flex;
}

.navbar a:hover {
  color: var(--golden);
}

.navbar .menu-icon {
  cursor: pointer;
  color: var(--golden);
  display: none;
}





/* --------------------------------------------------- */



.to_drop {
  list-style: none;
  padding: 0.5vmax 0;
  font-size: 1rem;
  text-wrap: wrap;
}

.to_drop>a {
  color: black !important;
}

.to_drop>a:hover {
  color: var(--golden) !important;
}

.small_group {
  background-color: black;
  /* background-color: red; */
  position: absolute;
  width: 22%;
  z-index: 5;
  display: none;
  list-style: none;
  padding: 0.5vmax 1.5vmax;
  border-radius: 0 0 1vmax 1vmax;
  padding-top: 1.1vmax;
  padding-bottom: 1.1vmax;

}

.small_group_Link {
  display: block;
  padding: 0.5vmax;
}

.to_drop:hover .small_group {
  display: block;
}

#id_R_by_destination {
  padding-top: 1.1vmax;
  width: 100%;
  position: absolute;
  left: 0px;
  /* top: 42px; */
  /* top: 5vmax; */
  display: none;
}

.to_drop:hover #id_R_by_destination {
  display: block;
}

/* /////////////////////sidenavbar/////////////// */


.side_destinations_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  font-size: 0.875rem;
  font-weight: 400;
}

.side_destinations_ul {
  margin: 10px;
}

.side_destinations_li {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  color: rgb(255 255 255/var(--tw-text-opacity));
}





/* --------------------------------------oi9 */

@media screen and (max-width: 1050px) {
  .navbar {
    font-size: 12px;
  }
}

@media screen and (max-width: 920px) {
  .nav-items {
    display: none;
  }



  .navbar .menu-icon {
    display: block;
  }

}




.responsive-navbar {
  position: fixed;
  top: 0%;
  right: -150%;
  padding: 1rem 2rem;
  max-width: 400px;
  height: 100vh;
  z-index: 999;
  background-color: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  transition: 1s;
  overflow: scroll;
}

.nav-link {
  text-align: center;
  font-family: Mont, Montserrat, Trebuchet MS, Helvetica, sans-serif !important;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .125em;
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
  display: block;
  width: 100%;
  position: relative;
  background: 0 0;
  border: none;
  font-size: .85rem;
  line-height: 1.666;
  padding: 1rem;

}

.side_nav_link {
  text-align: center;
  font-family: Mont, Montserrat, Trebuchet MS, Helvetica, sans-serif !important;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .125em;
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
  display: block;
  width: 100%;
  position: relative;
  background: 0 0;
  border: none;
  line-height: 1.666;
  padding: 1rem;
  cursor: pointer;
}

.close-icon {
  cursor: pointer;
  position: absolute;
  left: 2%;
  color: #fff;
  background-color: #e4bd5a;
  border-radius: 50%;
  padding: 0.5%;

}

.close-icon:hover {
  color: #e4bd5a;
  background-color: #fff;

}

.responsive-navbar ul li {
  /* transform: translateX(20px); */
  margin: 0.5rem 0.5rem;
}

.call-and-home-nav-item {
  font-size: 1rem;
  display: flex;
}

@media screen and (min-width: 920px) {
  .responsive-navbar {
    display: none;
  }

  .navbar-logo {
    width: 200px;
    height: 50px;
    margin-left: -1%;
  }
}
