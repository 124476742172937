.landinglanding-carousal {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* transition: 5s; */
  margin-top: 20px;
}

.landingslide-section {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: bottom center;
  opacity: 0;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  animation: zoomEffect 7s infinite alternate;
  /* Add animation for smooth zoom */
}

@keyframes zoomEffect {
  0% {
    transform: scale(1);
    /* Initial scale value for no zoom */
  }

  100% {
    transform: scale(1.1);
    /* Scale up to achieve the zoom effect */
  }
}

.landingslide-section.active {
  opacity: 1;
  /* animation: none;  */
}

.landingcontent {
  transform: translateY(80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  text-align: center;
  color: black;
  width: 80%;
  margin: auto;
  letter-spacing: 0.3rem;
}

.landingdots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.landingdot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 5px;
  cursor: pointer;
}

.landingdot.active {
  background-color: var(--golden);
}

.landingcontent p {
  /* width: 50%; */
  font-size: 1rem;
  display: inline;
  --tw-bg-opacity: 1;
  background-color: rgb(163 145 97/var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
  padding: 0.3125em 0.6875em;
  font-weight: 600;
  color: #fff;
  margin: auto;
  text-transform: uppercase;
  /* border: 2px solid #fff; */
}

.landingcontent h2 {
  letter-spacing: .3rem;
  font-size: 2.5em;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 3px 5px #000;

  padding: 0px 5px;
}

.shimmer-placeholder {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.1) 100%);
  position: absolute;
  top: 0;
  left: 0;
  animation: shimmerAnimation 1s infinite;
}

@keyframes shimmerAnimation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@media screen and (max-width: 500px) {
  .landinglanding-carousal {
    width: 100vw;
    height: 75vh;
  }

  .landingcontent {
    transform: translateY(80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
    color: black;
    width: 90%;
    margin: auto;
  }

  .landingcontent p {
    font-size: 1rem;
    display: inline;
    --tw-bg-opacity: 1;
    background-color: rgb(163 145 97/var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    padding: 0.3125em 0.6875em;
    font-weight: 600;
    color: #fff;
    margin: auto;
    text-transform: uppercase;

  }

  .landingcontent h2 {
    letter-spacing: .3rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 0 3px 5px #000;

    padding: 0px 5px;
  }
}

@media screen and (max-width: 800px) and (min-width: 501px) {
  .landinglanding-carousal {
    width: 100vw;
    height: 80vh;
  }



  .landingcontent p {
    font-size: 1rem;
  }

  .landingcontent h2 {
    letter-spacing: .3rem;
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 0 3px 5px #000;

    padding: 0px 5px;
  }
}