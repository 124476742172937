/* BespokeTravel.css */

body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #fff;
}

.bespoke-travel-container {
  background-color: #dbaa79;
  padding: 30px;
  min-height: 230px;
  margin-top: 53px;
}

.bespoke-travel-content {
  max-width: 95%;
  margin: 0 auto;
}

.bespoke-travel-title {
  max-width: 100%;
  margin: 15px auto;
  border-bottom: 1px solid #fff;
  padding-bottom: 15px 5px;
  text-align: center;
  color: #fff;

  font-size: 2rem !important;
  font-family: Lora, serif;
  font-weight: 300;
  text-transform: uppercase;
}

.bespoke-travel-description {
  color: #fff;
  font-size: 1.1rem;
  text-align: justify;
  font-weight: 400;
}

/* ////////////////////////// */
/* BespokeFormStyles.css */
/* BespokeFormStyles.css */
.bespoke-form-container {
  padding: 50px 20px;
  background-image: url("https://res.cloudinary.com/dxhoawdbh/image/upload/v1712292544/Ibrahim-Roza_vmbwim.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bespoke-form-content {
  max-width: 750px;
  background: #fff;
  border-radius: 8px;
  padding: 45px 45px;
  color: #494949;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.form-title {
  text-align: center;
  color: rgb(219, 170, 121);
  padding: 0px 15px;
  margin-bottom: 20px;
}

.form-description {
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;
  padding: 0px 15px;
}

.form-question {
  margin-bottom: 20px;
}

.form-question label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.select-container select,
.radio-group {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  color: #494949;
  margin-top: 5px;
}

.form-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.containerbtn {
  max-width: 750px;
  margin: 0 auto;
  color: #494949;
  padding: 15px 0px;

  cursor: pointer;
}


.buttonform {
  color: #fff;
  width: 115px;
  margin: 0px 8px;
  padding: 8px 16px;
  font-size: 16px;
  background-color: #DBAA79;
  outline: none;
  border-radius: 5px;
  border: none;
  border-radius: 8px 2px 8px 2px;
  cursor: pointer;
}

.buttonform:hover {
  box-shadow: 3px 1px -2px rgba(0, 0, 0, 0.2);
}