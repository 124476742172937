/* Footer.css */
.footer {
  width: 100%;
  background-color: #1a1a1a;
  color: #f5f5f5;
  position: relative;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footerTop {
  padding: 3rem 0;
}

.footerWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.footerSectionTitle {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #fff;
  position: relative;
  padding-bottom: 0.5rem;
}

.footerSectionTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 2px;
  background-color: #ff6b6b;
}

/* Office section styles */
.officesGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.officeCard {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1rem;
  transition: transform 0.3s ease;
}

.officeCard:hover {
  transform: translateY(-5px);
  background-color: rgba(255, 255, 255, 0.1);
}

.officeLocation {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
  justify-content: space-between;
}

.officeIcon {
  color: #ff6b6b;
  font-size: 1.2rem;
  margin-top: 0.25rem;
}

.officeAddress {
  width: 100%;
  display: flex;
  font-size: 0.95rem;
  line-height: 1.5;
}

/* Footer links styles */
.footerLinks {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.footerNav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footerNav li {
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
}

.footerNav > li > a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.95rem;
  display: block;
  padding: 0.25rem 0;
}

.footerNav a:hover {
  color: #ff6b6b;
}

/* Contact info styles */
.contactInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contactItem {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}

.contactIcon {
  color: #ff6b6b;
  font-size: 1rem;
  margin-top: 0.25rem;
}

.contactText {
  font-size: 0.95rem;
  line-height: 1.5;
}

/* Footer middle styles */
.footerMiddle {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 2rem 0;
}

.footerMiddleWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.newsletterTitle {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.newsletterForm {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.newsletterInput {
  padding: 0.75rem 1rem;
  border-radius: 4px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.95rem;
  width: 100%;
}

.newsletterInput::placeholder {
  color: #aaa;
}

.newsletterButton {
  background-color: #ff6b6b;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletterButton:hover {
  background-color: #ff5252;
}

/* Social links styles */
.socialLinks {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.socialLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.socialLink:hover {
  background-color: #ff6b6b;
  transform: translateY(-3px);
}

/* Footer bottom styles */
.footerBottom {
  background-color: #000;
  padding: 1.5rem 0;
  text-align: center;
}

.copyright {
  font-size: 0.9rem;
  color: #aaa;
}

.copyright a {
  color: #ff6b6b;
  text-decoration: none;
}

/* Float buttons */
.floatButtons {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 100;
}

.floatButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  font-size: 1.25rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  text-decoration: none;
}

.whatsappButton {
  background-color: #25d366;
}

.whatsappButton:hover {
  background-color: #1fbd58;
  transform: translateY(-3px);
}

.backToTop {
  background-color: #ff6b6b;
}

.backToTop:hover {
  background-color: #ff5252;
  transform: translateY(-3px);
}

/* Responsive styles */
@media (min-width: 768px) {
  .footerWrapper {
    grid-template-columns: 1fr 1fr;
  }

  .officesGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .footerLinks {
    grid-template-columns: repeat(3, 1fr);
  }

  .newsletterForm {
    flex-direction: row;
  }

  .newsletterInput {
    flex: 1;
  }

  .footerMiddleWrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.additionalOffices {
  padding: 12px 0;
  text-align: center;
}

.otherOfficesText {
  color: #ffffff;
  font-size: 1rem;
  margin: 0;
}

@media (min-width: 992px) {
  .footerWrapper {
    grid-template-columns: 1fr 2fr;
  }

  .officesGrid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1400px) {
  .officesGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}
