.main_container {
  width: 100%;
  color: #3C3D3D;
  background-color: white;
  margin-bottom: 100px;
}

.buttons_container {
  width: 70%;
  display: flex;
  margin: auto;
  justify-content: center;
  gap: 10px;
  /* align-items: center; */
}

.Logo_design {
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  padding: 40px 10px;
  position: relative;
  left: 0%;
  color: white;
  z-index: 0;
}

.buttons_container>button:hover {
  width: 100%;
  padding: 12px;
  background-color: white;
  color: #A39060;
  border: none;
  border: 2px solid #A39060;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  transition: background-color 0.3s ease;
  border-bottom: none;

}

.button_active {
  width: 100%;
  padding: 14px;
  background-color: white;
  color: #A39060;
  border: none;
  border: 2px solid #A39060;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  border-bottom: none;
  margin-bottom: -2px !important;
  z-index: 1;
}

.button_inactive {
  width: 100%;
  padding: 12px;
  background-color: #A39060;
  color: white;
  border: none;
  border: 2px solid #A39060;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
}

.content_container {
  border-top: 2px solid #A39060;
}

@media (max-width: 430px) {
  .buttons_container {
    width: 95%;
    display: flex;
    margin: auto;
    justify-content: center;
    gap: 10px;
    /* align-items: center; */
  }


  .button_active {
    width: 100%;
    padding: 8px;
    font-size: 8px;
    background-color: white;
    color: #A39060;
    border: none;
    border: 2px solid #A39060;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
    font-weight: bold;
    border-bottom: none;
  }

  .button_inactive {
    width: 100%;
    padding: 8px;
    font-size: 8px;
    background-color: #A39060;
    color: white;
    border: none;
    border: 2px solid #A39060;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
    font-weight: bold;
  }

  .buttons_container>button:hover {
    width: 100%;
    padding: 8px;
    font-size: 8px;
    background-color: white;
    color: #A39060;
    border: none;
    border: 2px solid #A39060;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    border-bottom: none;

  }
}