.testimonial-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  position: relative;
}

.scrollable-section::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 600px) {
  .testimonial-section {
    text-align: center;
  }

  .main-title .title {
    font-style: normal;
    letter-spacing: 0.02em;
    margin-bottom: 0;
    font-size: 2rem;
  }

  .main-title .paragraph {
    color: #181a20;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    text-align: center;
  }
}

.main-title {
  position: relative;
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {

  .main-title {
    margin-bottom: 20px;
  }

  .main-title .paragraph {
    color: #181a20;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    text-align: center;
  }
}

.main-title .title {
  font-style: normal;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

.main-title .paragraph {
  color: #181a20;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  text-align: center;

}