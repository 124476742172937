/* PrivacyPolicy.css */

/* Breadcrumb section */

.containerbreadcrumb {
    width: 100%;
    padding: 20px;
}

.breadcrumb-outer {
    position: relative;
    padding: 100px 0;
}

.breadcrumb-content h2 {
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
}

.breadcrumb {
    background: transparent;
    padding: 0;
    margin: 0;
}

.breadcrumb-item {
    display: inline-block;
    margin: 0;
}

.breadcrumb-item.active a {
    color: #fff;
}

.section-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

/* Privacy policy content section */
.privacy_policy {
    padding: 60px 0;
}

.privacy_policy h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
}

.privacy_policy p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
}

.privacy_policy ul {
    list-style: disc;
    padding-left: 20px;
    margin-bottom: 20px;
}

.privacy_policy ul li {
    margin-bottom: 8px;
}

.privacy_policy ul li:last-child {
    margin-bottom: 0;
}

/*
breadcrumb*/

section.breadcrumb-outer {
    padding: 95px 0px;
    position: relative;
    overflow: hidden;
}

section.breadcrumb-outer:before {
    /* background: url(../images/slider/slider6.jpg) no-repeat; */
    background-position: center;
    animation: kbrns_zoomInOut 15s linear 0s infinite alternate;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.section-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.breadcrumb-outer h2 {
    font-family: Poppins, sans-serif !important;
    color: #fff;
    margin: 0;
    font-size: 36px;
    padding: 10px 0 0px;
}

.breadcrumb-content li {
    font-family: Poppins, sans-serif;
}

.breadcrumb-content {
    position: relative;
    z-index: 1;
    margin: 0 auto;
}

.breadcrumb-content:before {
    position: absolute;
    content: '';
    height: 2px;
    background: #fff;
    width: 50px;
    top: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.breadcrumb-content nav {
    display: block;
}

.breadcrumb-content ul {
    margin-bottom: 0;
    background-color: transparent;
    padding: 12px 30px;
    margin: 0 auto;
}

.breadcrumb-content li {
    margin-bottom: 0;
}

.breadcrumb-content li a {
    transition: all ease-in-out 0.3s;
    color: #fff;
}

.breadcrumb-content li a:hover {
    color: #ffc63f;
}

.breadcrumb-content .breadcrumb>.active {
    color: #ffc63f;
}

.breadcrumb>li+li:before {
    padding: 0 10px 0 5px;
    content: '|'
}

/*pagination*/

ul.pagination {
    margin: 0;
}

.pagination-content {
    margin-top: 30px;
    text-align: center;
}

.pagination>li>a,
.pagination>li>span {
    height: 36px;
    width: 36px;
    line-height: 2;
    font-size: 18px;
    padding: 0;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover,
.pagination>li.active>a {
    background: #005294;
    color: #fff;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: 0;
}

label.error {
    color: red;
    margin-top: 5px;
    font-size: 14px;
}

.alert-success {
    background: #3c763d;
    border: none;
    color: #fff;
}

.alert-success h3 {
    margin-bottom: 5px;
    color: #fbfbfb;
}

.alert-success p {
    color: #fbfbfb;
}


section.breadcrumb-outer.tour_banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 130px 0px;
}

section.breadcrumb-outer.tour_banner:before {
    display: none;
}


.breadcrumb-outer.shop_banner {
    padding: 40px 0px !important;
}

@media (max-width:767px) {
    section.breadcrumb-outer.tour_banner {
        background-attachment: inherit;
        padding: 70px 0px;
    }
}

@media (max-width:480px) {
    section.breadcrumb-outer.tour_banner {
        background-attachment: inherit;
        padding: 50px 0px;
    }
}