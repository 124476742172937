.scroll-video-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  position: relative;
}

.scroll-video-section p {
  max-width: 800px;
  font-size: 1.4rem;
}

.video-section-video {
  width: 75%;
}

.video-carousel {
  display: flex;
  align-items: center;
  position: relative;
}

.video-section-video {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  cursor: pointer;
}

.carousel-arrow {
  position: absolute;
  font-size: 2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.main-title {
  position: relative;
  margin-bottom: 40px;
}

.main-title .title {
  font-style: normal;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

.main-title .paragraph {
  color: #181a20;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  text-align: center;

}

@media screen and (max-width: 600px) {
  .testimonial-section {
    text-align: center;
  }

  .main-title .title {
    font-style: normal;
    letter-spacing: 0.02em;
    margin-bottom: 0;
    font-size: 2rem;
  }

  .main-title .paragraph {
    color: #181a20;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    text-align: center;
  }
}



@media (max-width: 991.98px) {

  .main-title {
    margin-bottom: 30px;
  }

  .main-title .paragraph {
    color: #181a20;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    text-align: center;
  }
}