.NewAbout_aboutContainer__G3n_a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fcfbf7;
  padding: 5rem 0;
  margin: 100px;
}

@media(max-width: 800px) {
  .NewAbout_aboutContainer__G3n_a {
    align-items: center;
    text-align: center;
    padding: 2rem 0
  }
}

@media(max-height: 450px) {
  .NewAbout_aboutContainer__G3n_a {
    margin-top: 7rem
  }
}

.NewAbout_aboutContent__kZs2M {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(145deg, #e4bd5a42, #e2c8609a);
  padding: 2rem 3rem;
  box-shadow: 0px 0px 20px #e2c86056;
  border-radius: 16.433px;
  transition: .3s
}

.NewAbout_aboutContent__kZs2M:hover {
  transform: scale(1.05)
}

@media(max-width: 500px) {
  .NewAbout_aboutContent__kZs2M:hover {
    transform: scale(.9)
  }
}

@media(max-height: 450px) {
  .NewAbout_aboutContent__kZs2M:hover {
    transform: none
  }
}

@media(max-width: 1200px) {
  .NewAbout_aboutContent__kZs2M {
    scale: 1
  }
}

@media(min-width: 800px)and (max-width:1000px) {
  .NewAbout_aboutContent__kZs2M {
    scale: 1;
    padding: 1rem 3rem;
    width: 95%
  }
}

@media(max-width: 800px) {
  .NewAbout_aboutContent__kZs2M {
    flex-direction: column-reverse;
    padding: 1rem
  }
}

@media(max-width: 500px) {
  .NewAbout_aboutContent__kZs2M {
    flex-direction: column;
    width: auto;
    margin-top: 1rem;
    scale: .9
  }
}

@media(max-height: 450px) {
  .NewAbout_aboutContent__kZs2M {
    flex-direction: column
  }
}

@media(min-width: 1700px) {
  .NewAbout_aboutContent__kZs2M {
    width: 55%
  }
}

.NewAbout_black_white__d1w4b {
  width: 430px;
  height: 395px;
  border-radius: 10px;
  transition: .3s;
  object-fit: cover
}

@media(max-width: 800px) {
  .NewAbout_black_white__d1w4b {
    width: 480px;
    height: 420px
  }
}

@media(max-width: 500px) {
  .NewAbout_black_white__d1w4b {
    width: 350px;
    height: 320px
  }
}

.NewAbout_aboutContent__kZs2M:hover .NewAbout_black_white__d1w4b {
  width: 440px;
  height: 405px
}

.NewAbout_section2Txt__G3n8n {
  color: #000;
  font-size: 2.8rem;
  font-weight: 900;
  margin: 0 0 2rem
}

@media(max-width: 440px) {
  .NewAbout_section2Txt__G3n8n {
    font-size: 36px;
    margin: 1rem 0
  }
}

.NewAbout_section2par__XgbEk {
  color: rgba(57, 57, 57, .9);
  font-size: 1rem;
  font-weight: 300;
  margin-top: .2rem;
  opacity: .8;
  padding: .2rem;
  width: 75%;
  line-height: 1.6
}

.NewAbout_imgCont__Y_fwz {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  transition: .3s
}

@media(min-width: 800px)and (max-width:1000px) {
  .NewAbout_imgCont__Y_fwz {
    scale: .8
  }
}

@media(max-width: 440px) {
  .NewAbout_imgCont__Y_fwz {
    scale: .8
  }
}

.NewAbout_aboutContent__kZs2M:hover .NewAbout_imgCont__Y_fwz {
  padding: 0
}

.NewAbout_underline__uXrIm {
  width: 165px;
  height: 8px;
  border-radius: 1000px;
  background: var(--gr-4, linear-gradient(54deg, #e4bd5a 0, #9cfeff 100%))
}

.NewAbout_heading__Ae5Tt {
  color: #000;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0
}

.NewAbout_headingCont__ZZXiR {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  justify-content: center;
  margin-bottom: 5rem
}

@media(max-width: 440px) {
  .NewAbout_headingCont__ZZXiR {
    margin-bottom: 0
  }
}

.NewAbout_aboutBtn__9mWL9 {
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
  font-size: 18px;
  margin-top: 1rem;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  transition: .3s
}

.NewAbout_aboutBtn__9mWL9:hover {
  padding: 10px 20px;
  color: #000;
  background-color: #fff
}

.NewAbout_icon__q7p_j {
  display: none;
  transition: .3s
}

.NewAbout_aboutBtn__9mWL9:hover .NewAbout_icon__q7p_j {
  display: block;
  color: #000
}

.NewAbout_section2Content__9gvQg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center
}

@media(max-width: 500px) {
  .NewAbout_section2Content__9gvQg {
    align-items: center
  }
}

@media(max-height: 450px) {
  .NewAbout_section2Content__9gvQg {
    align-items: center
  }
}


@media screen and (max-width: 500px) {
  .previous-btn {
    padding: 0.5rem;
    background: var(--golden);
    color: white;
    font-size: 16px;
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    z-index: 1;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0%;
    bottom: 0%;
  }

  .next-btn {
    padding: 0.5rem;
    background: var(--golden);
    color: white;
    font-size: 16px;
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0%;
    bottom: 0%;
  }
}

@media screen and (max-width: 800px) {
  .previous-btn {
    padding: 0.5rem;
    background: var(--golden);
    color: white;
    font-size: 16px;
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0%;
  }



  .previous-btn {
    padding: 0.5rem;
    background: var(--golden);
    color: white;
    font-size: 16px;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    left: -1%;
    bottom: 0%;
  }
}


@media screen and (min-width: 1000px) {
  .slider-component {
    width: 80%;
    height: 60vh;
    margin: 2rem 0;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .previous-btn {
    padding: 0.5rem;
    background: var(--golden);
    color: white;
    font-size: 16px;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    left: -1%;
    top: 50%;
  }

  .next-btn {
    padding: 0.5rem;
    background: var(--golden);
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: absolute;
    right: -1%;
    top: 50%;
  }
}