.R_by_destination {
    width: 100%;
    padding: 1.5vmax;
    /* padding: 20px; */
    background-color: black;
    /* background-color: green; */
    display: flex;
    flex-direction: row;
    /* height: 78vh ; */
    height: 41.5vmax;
    width: 100%;
    font-family: "Montserrat", sans-serif;
}


.all_destinations_container {
    width: 100%;
}

.all_destinations_container:hover {
    width: 100%;
    background-image: linear-gradient(to bottom, #ffffff24, black);
}

.regional_destinations_container {
    width: 25%;
}

.regional_destinations_container:hover {
    background-image: linear-gradient(#ffffff1b, black);
    width: 25%;
}

.all_itineraries_container {
    width: 30%;
}

.all_itineraries_container:hover {
    background-image: linear-gradient(#eef95a21, black);

}

.all_destinations_li {
    list-style-type: none;
    display: inline-block;
    /* LI */
    /* background-color: #ffffff0f; */
    background-color: #ffffff06;
    /* margin: 6px 16px; */
    margin: 0.5vmax 0.9vmax;
    padding: 0.1vmax 0;
}

.side_destinations_li {
    list-style-type: none;
    list-style: none;
    background-color: #ffffff06;

}

.all_destinations_li:hover {
    /* background-color: #ffffff0f; */
    background-color: #ffffff14;
    /* background-color: #ffffff1c; */
}

.side_destinations_li:hover {
    /* background-color: #ffffff0f; */
    background-color: #ffffff14;
    /* background-color: #ffffff1c; */
}

/* .all_destinations_li Link{ */
.all_destination_Link {
    display: inline-block;
    width: 100%;
    font-size: 1vmax;
    color: white;
    text-decoration: none;
}

/* .all_destinations_li Link:hover{ */
.all_destination_Link:hover {
    color: var(--golden);
}


.all_destinations_ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* width: 100px; */
    /* background-color: red; */
    /* height: 60vh; */
    height: 32vmax;
    padding: 0.2vmax 1.5vmax;
}

.all_destinations_h4 {
    font-size: 1.3vmax;
    padding: 0 2.4vmax;
    color: white;
    margin-bottom: 1.1vmax;
    margin-top: 2.6vmax;

}

/* -++++++++++++++++++++++++++++++++++++++++++++++ */
.regional_destinations_li {

    box-sizing: content-box;
    list-style-type: none;
    display: flex;
    /* margin: 5px 0px; */
    margin: 0.5vmax 0.9vmax;
    padding-left: 1.1vmax;
    margin-left: 0vmax;
    /* border: 1px solid red; */
}

.regional_destinations_li::before {
    content: "\2022";
    color: #a39161;
    font-weight: bolder;
    display: inline-block;
    /* width: 1em; */
    width: 1.1vmax;
    /* margin-left: -1em; */
    margin-left: -1.1vmax;
}

.sideregional_destinations_li {
    max-width: 50vw !important;
}

.sideregional_destinations_li::before {
    content: "\2022";
    color: #a39161;
    font-weight: bolder;
    display: inline-block;
}

.regional_destinations_Link {
    font-size: 1vmax;
    color: white;
    text-decoration: none;
}

.regional_destinations_Link:hover {
    color: var(--golden);
}

.regional_destinations_h4 {
    font-size: 1.3vmax;

    padding: 0 2vmax;
    color: white;
    margin-bottom: 0.8vmax;
    margin-top: 2.6vmax;
}

.regional_destinations_ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 60vh;
    padding: 2.2vmax;
    flex-direction: column;
}

.sidenavregional_ul {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}


/* +-+-+-+-+-++++++++++----++++++++++++-----++++--++++++++++++++++---++ */

.divvv {
    background-image: url("https://images.unsplash.com/photo-1682687220801-eef408f95d71?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80");
    background-repeat: no-repeat;
    background-size: cover;
    /* padding: 0 25px; */
    background-color: pink;
    height: 50vh;
    width: 85%;
    margin: auto;
    margin-bottom: 1.1vmax;
    margin-top: 2.6vmax;
    border-radius: 1vmax;
    /* padding: 25px; */
    /* padding: 40px; */
}

.divvv Link {
    text-decoration: none;
    /* font-size: ; */
}


.img_B_D {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-end;
    /* padding: 20px 20px; */
    padding: 2vmax 2vmax;
    height: 100%;
    box-sizing: border-box;
    /* background-color: green; */
    /* align-items: center; */
    color: white;

}

.all_itineraries_h4 {
    display: block;
    font-size: 1.5vmax;
    color: white;
    background-color: #fed86fa3 !important;
    margin: 1vmax 0;
    padding: 0.1vmax 0.2vmax;
    border-radius: 0.2vmax;
}

.all_itineraries_h4 span {
    font-weight: bolder;
    margin-left: 0.3vmax;
}

/* .hsidebar-header .sidebar-close-icon:hover {
    background-color: #eb6753;
    color: #fff;
} */

.hsidebar-header {
    background: rgba(235, 103, 83, 0.07);
}

.sidebar-close-icon {
    background-color: #eb6753;
    color: #fff;
}